<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import {
  required, url
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Social Media",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Social Media",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Social Medias",
          active: true
        },
      ],
      form: {
        facebook: null,
        twitter: null,
        instagram: null,
        youtube: null
      },
      typesubmit: false,
      isError: false,
      errorMessage: null
    };
  },
  validations: {
    form: {
      facebook: { required, url },
      twitter: { required, url },
      instagram: { required, url },
      youtube: { required, url },
    }
  },
  mounted() {
    this.getSocmed()
  },
  methods: {
    getSocmed() {
      this.$axios.get(`/social_media`)
        .then( (response) => { 
          const result = response.data.data

          this.form = {
            facebook: result.facebook,
            twitter: result.twitter,
            instagram: result.instagram,
            youtube: result.youtube
          }
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    onSubmit() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {

        let param = new FormData();
        param.append('facebook', this.form.facebook)
        param.append('twitter', this.form.twitter)
        param.append('instagram', this.form.instagram)
        param.append('youtube', this.form.youtube)

        this.$axios.post('/social_media', param)
        .then( (result) => { 
          console.log(result.data)
           this.isError = false
          Swal.fire("Success", "Social Media Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">

              <div class="form-group">
                <label>Facebook</label>
                <input
                  v-model="form.facebook"
                  type="text"
                  class="form-control"
                  name="facebook"
                  :class="{ 'is-invalid': typesubmit && $v.form.facebook.$error }"
                />
                <div v-if="typesubmit && $v.form.facebook.$error" class="invalid-feedback">
                  <span v-if="!$v.form.facebook.required">This value is required.</span>
                  <br>
                  <span v-if="!$v.form.facebook.url">This value must URL format.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Twitter</label>
                <input
                  v-model="form.twitter"
                  type="text"
                  class="form-control"
                  name="twitter"
                  :class="{ 'is-invalid': typesubmit && $v.form.twitter.$error }"
                />
                <div v-if="typesubmit && $v.form.twitter.$error" class="invalid-feedback">
                  <span v-if="!$v.form.twitter.required">This value is required.</span>
                  <br>
                  <span v-if="!$v.form.twitter.url">This value must URL format.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Instagram</label>
                <input
                  v-model="form.instagram"
                  type="text"
                  class="form-control"
                  name="instagram"
                  :class="{ 'is-invalid': typesubmit && $v.form.instagram.$error }"
                />
                <div v-if="typesubmit && $v.form.instagram.$error" class="invalid-feedback">
                  <span v-if="!$v.form.instagram.required">This value is required.</span>
                  <br>
                  <span v-if="!$v.form.instagram.url">This value must URL format.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Youtube</label>
                <input
                  v-model="form.youtube"
                  type="text"
                  class="form-control"
                  name="youtube"
                  :class="{ 'is-invalid': typesubmit && $v.form.youtube.$error }"
                />
                <div v-if="typesubmit && $v.form.youtube.$error" class="invalid-feedback">
                  <span v-if="!$v.form.youtube.required">This value is required.</span>
                  <br>
                  <span v-if="!$v.form.youtube.url">This value must URL format.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>